









































































import Vue from 'vue'
import api from './services/api'
import snackbar from './services/snackbar'

import { USER_LOGIN, USER_LOGOUT, SET_USERDATA, STOP_LOADING } from './store/constants'
import { routes } from './router/index'

import { User } from '../../shared/interfaces'

export default Vue.extend({
  name: 'App',

  data () {
    return {
      drawer: '',
      routes
    }
  },

  computed: {
    routeName () {
      return this.$route.name
    },

    loggedIn () {
      return this.$store.state.loggedIn
    },

    loading () {
      return this.$store.state.loading
    },

    snackbar () {
      return this.$store.state.snackbar
    }
  },

  methods: {
    async logout () {
      try {
        const res = await api.post('logout', {})

        if (res.status === 200) {
          this.$store.dispatch(USER_LOGOUT)
        }
      } catch (err) {
        snackbar.error()
      }
    },

    closeSnackbar () {
      snackbar.close()
    }
  },

  async created () {
    try {
      const res = await api.get<User>('user')

      if (res.status === 200) {
        await this.$store.dispatch(SET_USERDATA, res.data)
      }
    } catch (err) {
      if (this.$route.path !== '/login') this.$router.push('/login')
      this.$store.commit(STOP_LOADING)
    }
  }
})
