




























import Vue from 'vue'

import helper from '@/services/helper'

import { Session } from '../../../../../../shared/interfaces'

export default Vue.extend({
  name: 'SessionGearForm',

  props: {
    value: Object as () => Session,
    sails: Array as () => string[],
    boards: Array as () => string[]
  },

  computed: {
    session: {
      get (): Session {
        return this.value
      },
      set (value: Session): void {
        this.$emit('input', value)
      }
    },

    getNumberArray () {
      return helper.getNumberArray
    }
  },

  data () {
    return {
      required: [
        (v: string) => !!v || 'All fields are required'
      ]
    }
  }
})
