











import Vue from 'vue'
export default Vue.extend({
  name: 'FormError',

  props: {
    msg: String
  }
})
