





































import Vue from 'vue'

import snackbar from '../../services/snackbar'

import DialogComponent from './DialogComponent.vue'
import EditSessionCard from './EditSessionCard.vue'
import SessionView from './SessionView.vue'

import { Session } from '../../../../shared/interfaces/Session'
import { UPDATE_SESSION } from '../../store/constants'

export default Vue.extend({
  name: 'SessionCard',

  components: {
    DialogComponent,
    EditSessionCard,
    SessionView
  },

  props: {
    session: Object as () => Session
  },

  computed: {
    date () {
      return new Date(this.session.date).toLocaleDateString()
    }
  },

  data () {
    return {
      showEditSessionCard: false
    }
  },

  methods: {
    async updateSession (session: Session) {
      try {
        await this.$store.dispatch(UPDATE_SESSION, session)

        snackbar.succes('Session updated succesfully!')
        this.showEditSessionCard = false
      } catch (err) {
        snackbar.error()
      }
    },

    closeModal () {
      this.$emit('close')
    }
  }
})
