























import Vue from 'vue'

import SessionForm from '@/components/ui/form/session/SessionForm.vue'

import { Session } from '../../../../shared/interfaces'

export default Vue.extend({
  name: 'SessionCard',

  components: {
    SessionForm
  },

  props: {
    initialSession: Object as () => Session
  },

  data () {
    return {
      session: this.initialSession,
      sessionFormState: {
        submitting: false,
        formErrorMsg: ''
      }
    }
  },

  methods: {
    closeModal () {
      this.$emit('close')
    },

    updateSession (session: Session) {
      this.sessionFormState.submitting = true
      this.$emit('updateSession', session)
    }
  }
})
