





































































































import Vue from 'vue'

import { Session } from '../../../../shared/interfaces/Session'

export default Vue.extend({
  name: 'SessionView',

  props: {
    session: Object as () => Session
  },

  computed: {
    date (): string {
      return new Date(this.session.date).toLocaleDateString()
    }
  }
})
