





























































import Vue from 'vue'
import snackbar from '../services/snackbar'

import FormError from '../components/ui/form/FormError.vue'

import { SET_USERDATA, USER_LOGIN } from '../store/constants'
import { User } from '../../../shared/interfaces/User'

export default Vue.extend({
  components: {
    FormError
  },

  data () {
    return {
      user: {
        name: '',
        password: ''
      },
      usernameRules: [
        (v: string) => !!v || 'Username is required'
      ],
      passwordRules: [
        (v: string) => !!v || 'Password is required'
      ],
      formError: false,
      formErrorMsg: '',
      allowRegister: process.env.VUE_APP_ALLOW_REGISTER === 'true',
      submitting: false
    }
  },

  methods: {
    submit () {
      if (!this.user.name || !this.user.password) {
        this.setError('Username or password missing!')
        return
      }

      this.submitting = true

      this.$store.dispatch(USER_LOGIN, this.user)
        .catch(err => {
          this.submitting = false
          if (err === 422 || err === 401) {
            this.setError('Invalid credentials')
          } else {
            snackbar.error()
          }
        })
    },

    setError (msg: string): void {
      this.formErrorMsg = msg
      this.formError = true
    }
  }
})
